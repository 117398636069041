<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">MY LEAVE LIST</span>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3 text-right>
            <v-btn
              color="#005f32"
              rounded
              outlined
              :to="'/Attendance/leaveForm'"
              ><span style="font-family: kumbhRegular"
                >Apply Leave</span
              ></v-btn
            >
          </v-flex>
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >*Select the options to filter the results</span
                >
              </v-flex>
              <v-flex xl3 lg3 md3 pb-2 sm6 xs12 pl-0 pl-xs-0 pl-sm-1 pl-md-1 pl-lg-1 pl-xl-1 pr-0 pr-xs-0 pr-sm-2 pr-md-2 pr-lg-2 pr-xl-2>
                <v-select
                  :items="lists"
                  dense
                  v-model="status"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="rounded-xl"
                ></v-select>
              </v-flex>

              <v-flex xl3 lg3 md3 pb-2 sm6 xs12 px-0 px-xs-0 px-sm-2 px-md-2 px-lg-2 px-xl-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      outlined
                      hide-details
                      dense
                  class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    type="month"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start v-if="leaveArray.length > 0">
          <v-flex xs12 sm12 md6 lg6 xl6 pa-5 v-for="(item, i) in leaveArray" :key="i">
            <v-card color="#ebe9e6" height="100%">
              <v-layout wrap pa-5>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex>
                      <span
                        style="
                          text-transform: uppercase;
                          font-family: kumbhBold;
                        "
                        >{{ item.leaveCategory }} LEAVE &nbsp;</span
                      >
                      <span style="font-family: kumbhBold"
                        ><v-btn
                          rounded
                          elevation="0"
                          small
                          dark
                          :color="getStatusColor(item.status)"
                          >{{ item.status }}</v-btn
                        ></span
                      >
                    </v-flex>
                    <v-flex text-right v-if="item.status == 'approved' && item.isWithdrawable">
                      <v-btn
                        @click="withdrawLeave(item._id)"
                        color="#095e05"
                        dark
                        small
                      >
                        <span style="font-family: kumbhBold">
                          Withdraw</span
                        >
                      </v-btn>
                    </v-flex>
                    <v-flex text-right v-if="item.status == 'pending'">
                      <v-btn
                        @click="cancelLeave(item._id)"
                        color="red"
                        dark
                        small
                      >
                        <span style="font-family: kumbhBold">
                          <v-icon small>mdi-close</v-icon> Cancel</span
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap py-5 justify-start>
                    <v-flex xs12 text-left>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black">mdi-calendar</v-icon>
                            Date</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            {{ formatDate(item.create_date) }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <!-- <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black">mdi-calendar</v-icon>
                            Required On</span
                          >
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                        >
                          <span class="itemHeading2" v-for="(lv, i) in item.leaveRequiredOn"
                          :key="lv">
                            {{ formatDate(lv) }}
                          <span v-if="i < item.leaveRequiredOn.length - 1"
                            >,</span
                          ></span
                          >
                        </v-flex>
                      </v-layout> -->
                      
                      <v-layout wrap py-3 align-center>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black">mdi-calendar</v-icon>
                            Required On
                          </span>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-layout  align-center>
                            <v-flex>

                        
                            <span class="itemHeading2" v-for="(lv, i) in visibleDates(item)" :key="lv">
                              {{ formatDate(lv) }}
                              <span v-if="i < visibleDates(item).length - 1">,</span>
                            </span>
                            <v-btn
                              v-if="!item.showAllDates && item.leaveRequiredOn.length > 1"
                              small
                              text class="ml-2"
                              color="blue"
                              @click="showMoreDates(item)"
                            >
                              See More
                            </v-btn>
                            <v-btn
                              v-if="item.showAllDates && item.leaveRequiredOn.length > 1"
                              small
                              text
                              color="red"
                              class="ml-2"
                              @click="showLessDates(item)"
                            >
                              Close
                            </v-btn>
                          </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black">mdi-calendar</v-icon>
                            No. of Day(s)</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            {{ item.leaveRequiredOn.length }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black">mdi-note</v-icon>
                            Type</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 v-if="item.leaveType=='FN'">
                          <span class="itemHeading2">
                            First Half</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 v-if="item.leaveType=='AN'">
                          <span class="itemHeading2">
                          Second Half</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 v-if="item.leaveType=='Fullday'">
                          <span class="itemHeading2">
                            Full Day</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black"
                              >mdi-table-of-contents</v-icon
                            >
                            Purpose</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2"> {{ item.reason }}</span>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3 v-if="item.status=='approved'">
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black"
                              >mdi-file-question</v-icon
                            >
                            Approved By</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 v-if="item.actionBy">
                          <span class="itemHeading2"> {{ item.actionBy.name }} (EmpID: {{ item.actionBy.employeeId }})</span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap py-3 v-if="item.status=='rejected'">
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black"
                              >mdi-file-question</v-icon
                            >
                            Rejected By</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 v-if="item.actionBy">
                          <span class="itemHeading2"> {{ item.actionBy.name }} (EmpID: {{ item.actionBy.employeeId }})</span>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3 v-if="item.status=='rejected'">
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            <v-icon small color="black"
                              >mdi-table-of-contents</v-icon
                            >
                            Remarks</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 v-if="item.remarks != '' && item.status=='rejected'">
                          <span class="itemHeading2"> {{ item.remarks }}</span>
                        </v-flex>
                        <v-flex xs12 sm6 v-else>
                          <span class="itemHeading2">No remarks</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data: (vm) => ({
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    status: "",
    lists: [
      {
        name: "All",
        value: "",
      },
      { name: "Pending", value: "pending" },
      { name: "Approved", value: "approved" },
      { name: "Rejected", value: "rejected" },
      { name: "Withdrawn", value: "withdrawn" },

    ],
    leaveArray: [],
    data: [],
    name: null,
    assetId: null,
    date: new Date().toISOString().substr(0, 7),
    dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
    menu1: false,
    menu2: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDateMonth(this.date);
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    date() {
      this.currentPage = 1;
      this.dateFormatted = this.formatDateMonth(this.date);
      if (this.computedDateFormatted) {
        this.getData();
      }
    },
    status() {
      this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },

  methods: {
    visibleDates(item) {
      // Return either all dates or just the first two based on the state
      return item.showAllDates ? item.leaveRequiredOn : item.leaveRequiredOn.slice(0, 1);
    },
    showMoreDates(item) {
      // Show all dates
      this.$set(item, 'showAllDates', true);
    },
    showLessDates(item) {
      // Show only the first two dates
      this.$set(item, 'showAllDates', false);
    },
    getStatusColor(status) {
      if (status === "approved") {
        return "success";
      }
      if (status === "rejected") {
        return "danger";
      }
      if (status === "pending") {
        return "info";
      }
      if (status === "withdrawn") {
        return "#095e05";
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/my/leave/application/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          page: this.currentPage,
          limit: 20,
          month: this.computedDateFormatted,
          status: this.status,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.leaveArray = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    cancelLeave(item) {
      axios({
        method: "POST",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        url: "/leave/application/delete/" + item,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar=true
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar=true
          }
        })
        .catch((err) => {
          this.visible = false;
          this.errors.push(err);
        });
    },
    withdrawLeave(item) {
      axios({
        method: "POST",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        url: "/leave/application/withdraw/" + item,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar=true
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar=true
          }
        })
        .catch((err) => {
          this.visible = false;
          this.errors.push(err);
        });
    },
    formatDate(item) {
  const dt = new Date(item);
  const day = String(dt.getDate()).padStart(2, '0'); // Get day with leading zero
  const month = String(dt.getMonth() + 1).padStart(2, '0'); // Get month with leading zero
  const year = dt.getFullYear(); // Get full year
  return `${day}-${month}-${year}`;
},

    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
  },
};
</script>
  <style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>
    